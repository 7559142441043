/* laptop */
@media only screen and (max-width: 1580px) {
    .col-sm-1 {
        padding: 0 !important;
        margin-top: 2% !important;
    }

    .card-stats .card-body {
        padding: 3px !important;
    }

    .workdetail h3 {
        font-size: 16px !important;
        padding-bottom: 5px;
    }

    .col-sm-1.addReadingsssB{
        margin-left: -60px !important;
        margin-top: 15px !important;
      }

    .nav.mr-auto.name.navbar-nav {
        width: 57% !important;
        flex: 1 0 50%;
    }

    .morenoti.col-3 {
        height: 150px;
    }

    .col-sm-6.addbuttons button.ant-btn.bluebtn.form {
        width: 40% !important;
    }
    .filteraccordian h2 {
        font-size: 14px !important;
    }
    .filteraccordian .col-sm-2 {
        padding: 10px 0 !important;
        margin: 0 0px !important;
        max-width: 30% !important;
    }

    .prospectusss{
        width: 200px !important;
    }

    .filteraccordian .col-sm-10{
        max-width: 70% !important;
    }
    .workCustomer h4 {
        font-size: 15px;
    }
    .workSelect {
        height: 150px;
        margin-top: 25px;
    }
    .filteraccordian .col-sm-2 {
        padding: 10px 0 !important;
        margin: 0 2px !important;
        max-width: 15% !important;
    }
    .workorder.customer.card img {
        width: 100% !important;
    }
    .switchbtn .ant-row.ant-form-item-row {
        padding-left: 8px !important;
    }
    .switchbtn .ant-row.ant-form-item-row label {
        font-size: 12px;
    }
    .ant-switch .ant-switch-handle {
        width: 14px !important;
        height: 14px !important;
    }

    .wordkorder .swiper-button-prev {
        position: absolute !important;
        right: 6% !important;
    }

    .ant-switch{
        min-width: 30px !important;
        height: 19px !important;
    }
    .ant-select-selector {
        height: 40px !important;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 50px !important;
        padding: 0 11px;
    }

    /* .col-sm-2.customertype {
        margin-top: -15px;
    } */

    .col-sm-1.addReadingsssB{
        margin-left: -60px !important;
        margin-top: 7px !important;
      }

    /* .customertype .ant-select .ant-select-arrow {
        margin-top: 3px !important;
    }

    .col-sm-4 .ant-select .ant-select-arrow{
        margin-top: 3px !important;
    } */

    .col-sm-12.laborReport{
        padding: 0px !important;
    }
    
    .col-sm-5.installedItems{
        padding: 0px !important;
    }
    
    .col-sm-5.chemicalDosages{
        padding: 0px !important;
        margin-left: -5px !important;
    }

    .col-sm-7.right.chemicalBtns{
        padding: 0px !important;
        margin-left: -20px !important;
    }
}

@media only screen and (max-width: 1450px) {
    form.dosaaaggeesss .col-sm-2 button{
        min-width: 90% !important;
    }

    .prospectusss{
        width: 170px !important;
    }

    .wordkorder .swiper-button-prev {
        position: absolute !important;
        right: 7% !important;
    }
    .col-sm-3 {
        padding: 0;
    }
    .ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
        padding: 0px;
    }

    .equipmentssssblue{
        margin: 20px 0px !important;
    }

    .miles div{
        padding: 0px 0px;
    }
    form.tableFilters.invoiceFilter {
        right: -47.5%;
    }

    td, th {
        padding: 20px !important;
    }

    p {
        font-size: 13px !important;
    }

    table {
        border-collapse: collapse;
        width: 900px;
        overflow-x: scroll;
    }

    .routedashboard.mainpage table{
        width: 100% !important;
    }

    .customertable table {
        width: 100%;
    }

    .laborReporttable table{
        width: 100% !important;
    }

    .card-stats .card-body {
        height: 155px;
    }

    .col-sm-6.addbuttons button.ant-btn.bluebtn.form {
        width: 40% !important;
    }
    .switchbtn .ant-col.ant-form-item-control {
        text-align: end;
        padding: 0 8px;
    }
}

@media only screen and (max-width: 1370px) {
    form.dosaaaggeesss .col-sm-2 button{
        min-width: 89% !important;
    }
    .workdetail h3 {
        font-size: 14px !important;
        padding-bottom: 5px;
    }
    
    .workSlider .swiper-button-prev {
        position: absolute !important;
        right: 20% !important;
    }

    .miles div{
        padding: 0px 10px;
    }

    .progressbar p {
        font-size: 12px !important;
    }

    .col-sm-4.text-right {
        font-size: 14px;
    }

    .col-sm-4.switchbtn {
        max-width: 31% !important;
    }

    .col-sm-6.addbuttons button.ant-btn.bluebtn.form {
        width: 40% !important;
    }

    .ant-switch {
        min-width: 34px !important;
        height: 22px;
    }

    .switchbtn .ant-col.ant-form-item-control {
        text-align: end;
        padding: 4px !important;
    }

    .ant-col.ant-form-item-label {
        width: 72% !important;
    }

    .row.grayshade {
        background: #f0f0f0;
        margin-top: 3%;
        padding: 20px 0;
        border-radius: 20px;
    }

    .accordion-item {
        padding: 10px;
    }

    .bodyroute h4 {
        margin: 0;
        font-size: 16px !important;
        color: #000;
        font-weight: 700;
        line-height: 18px;
    }

    .bodyroute.row span {
        font-size: 10px;
    }

    .miles .col-sm-4{
        font-size: 10px !important;
    }

    .filteraccordian .col-sm-2 {
        padding: 10px 0 !important;
        margin: 0 0px !important;
        max-width: 20% !important;
    }

    .col-sm-2.roueTabs{
        padding: 10px 0 !important;
        margin: 0 0px !important;
        min-width: 30% !important;
        max-width: 100% !important;
    }
    .col-sm-6.roueTabsMax{
        padding: 10px 0 !important;
        margin: 0 0px !important;
        min-width: 90% !important;
        max-width: 100% !important;
    }

    .col-sm-6.roueTabsMax h2{
        font-size: 16px !important;
    }

    .col-sm-4.roueTabsMaxContent{
        font-size: 12px !important;
    }

    .workCustomer h4 {
        font-size: 14px;
    }

    .ant-select-selector {
        height: 40px !important;
    }

    .card .card-title {
        font-size: 19px;
    }

    .col-sm-12.routeLocationMap .bluebtn{
        padding: 10px 20px !important;
    }

}

@media only screen and (max-width: 1290px) {
    .monthFilterSelect{
        width: 60% !important;
      }
      
      .col-sm-6.monthDatePicker .ant-picker {
        width: 60% !important;
        height: 50px !important;
      }

    form.dosaaaggeesss .col-sm-2 button{
        min-width: 88% !important;
    }

    .wordkorder .swiper-button-prev {
        position: absolute !important;
        right: 8% !important;
    }

    .card-stats .card-body {
        padding: 25px !important;
        height: 160px;
        position: relative;
    }

    label.ant-radio-wrapper {
        font-size: 12px;
    }

    .card {
        padding: 5px;
    }

    .card-body .row {
        height: 140px;
    }

    .googlemap_frame {
        height: 600px !important;
    }

    .col-md-4,
    .col-md-8 {
        padding: 5px !important;
    }

    .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 16px 0 0;
        padding: 12px 30px;
    }

    .ant-tabs .ant-tabs-tab {
        margin: 0 16px 0 0 ;
        padding: 12px 30px;
    }

    .col-sm-6.addbuttons button.ant-btn.bluebtn.form {
        width: 45% !important;
    }

    .workSlider .swiper-button-prev {
        position: absolute !important;
        right: 22.5% !important;
    }
}

@media only screen and (max-width: 1160px) {
    .wordkorder .swiper-button-prev {
        position: absolute !important;
        right: 9% !important;
    }
    .col-9 {
        flex: 0 0 70% !important;
        max-width: 70% !important;
        padding: 0 !important;
    }

    .addReadingsssSaveBtn{
        width: 30% !important;
      }

    .workSlider .swiper-button-prev {
        position: absolute !important;
        right: 25% !important;
    }

    .col-sm-12.routeLocationMap{
        display: flex;
    }

    .icon-big.text-center.icon-warning h2 {
        font-size: 19px;
    }

    .icon-big.text-center.icon-warning p {
        font-size: 12px !important;
    }

    .card-stats .card-body {
        padding: 18px !important;
        height: 120px !important;
        padding-right: 10px !important;
    }

    .card-body .row {
        height: 100px !important;
    }

    .workorder h4 {
        font-weight: 600 !important;
        font-size: 14px;
    }

    .workdetail h3 {
        font-size: 12px !important;
        padding-bottom: 5px;
    }

    .workdetail p {
        font-size: 10px !important;
        margin-right: 12px !important;
    }

    .col-sm-4.text-right {
        font-size: 10px;
    }

    .col-sm-6.text-right span {
        font-size: 9px;
        font-weight: 600;
    }

    .col-sm-12.progressbar .col-sm-6 {
        display: flex;
        align-items: center;
    }

    .d-flex.justify-content-center.align-items-center.ml-2.ml-lg-0 {
        /* padding-left: 0px !important; */
        padding-right: 0px !important;
    }

    .routedashboard.mainpage.customertable {
        overflow-x: scroll;
    }

    .routedashboard.mainpage.laborReporttable{
        overflow-x: scroll;
    }

    form.myfilters select {
        width: 25%;
        background: #BFBFBF;
        border: unset !important;
        padding: 10px;
        color: #fff !important;
        cursor: pointer;
        padding-right: 20px;
        padding-left: 10px;
        font-size: 12px;
    }

    form.techniciaaannnn select{
        width: 20% !important;
    }

    form.myfilters input {
        width: 40%;
        background: transparent;
        border: unset !important;
        padding: 10px;
        font-size: 14px;
    }

    .morenoti.col-3 {
        height: 100px;
    }

    
    .searchform.nav-item {
        margin-left: 30px;
    }


    a.m-0.nav-link {
        padding: 0px !important;
    }
}

@media only screen and (max-width: 1100px){

      
      .col-sm-6.monthDatePicker .ant-picker {
        width: 70% !important;
        height: 50px !important;
      }
    .searchform.nav-item {
        width: 250px;
        margin-left: 30px;
    }
      
    .searchform.nav-item input {
        width: 200px;
    }

    .workSlider .swiper-button-prev {
        position: absolute !important;
        right: 30% !important;
    }
}

@media only screen and (max-width: 991px){

    .col-sm-5.installedItems{
        min-width: 100% !important;
    }

    nav.navbar.navbar-expand-lg.navbar-light.bg-light {
        padding: 10px 5px !important;
        background: #1A4A5B !important;
    }

    .content.dasssssshhhBoarrddd .container-fluid{
        padding-left: 0px !important;
    }

    .col-sm-2 .notificationImage{
        width: 35px !important;
        height: 35px !important;
    }

    .row.workaddbtn {
        margin-top: -60px;
    }

    .navbar .navbar-brand {
        font-weight: 400;
        margin: 5px 0px;
        font-size: 20px;
        color: #fff !important;
    }

    .navbar .navbar-toggler .burger-lines {
        background-color: #fff !important;
    }

    .nav.mr-auto.name.navbar-nav .nav-item {
        display: none;
    }

    .searchform.nav-item {
        display: block !important;
        margin: auto;
    }

    .navbar-light .navbar-nav .nav-link {
        color: #fff !important;
    }

    .nav.mr-auto.name.navbar-nav {
        width: 100% !important;
        flex: 0 0 25%;
        margin-top: 15px;
    }

    .ml-auto.hassan.navbar-nav {
        display: flex;
        flex-direction: inherit;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .avatardrop .dropdown-menu {
        transform: scale(1) !important;
        background: #F0B51B !important;
        /* margin-top: 15px !important; */
        margin: 15px 10px 0px !important;
    }   

    .avatardrop .dropdown-menu a {
        color: #fff !important;
    }

    .notNotidrop .dropdown-menu {
        transform: scale(1) !important;
        background: #F0B51B !important;
        color: white !important;
        /* margin-top: 15px !important; */
        margin: 15px 10px 0px !important;
    }   

    .notidrop.notNotidrop.dropdown.nav-item {
        padding-left: 10px;
    }

    .navbar .navbar-nav .notification {

        margin-left: 10px;
    }

    .notNotidrop .dropdown-menu a{
        color: #fff !important;
    }
    
    .nav.mr-auto.name.navbar-nav {
        width: 100% !important;
        flex: 0 0 50%;
    }

    .ml-auto.hassan.navbar-nav {
        display: flex !important;
        flex-direction: inherit;
        justify-content: space-between;
        margin-top: 15px;
    }

    .btn.btn-fill {
        background-color: #F0B51B !important;
        border: unset !important;
    }

    .name .nav-item {
        text-align: center;
    }

    .navbar-nav .nav-item {
        display: block;
        width: 100%;
        margin-left: 0px !important;
    }

    .searchform.nav-item input {
        width: 95%;
    }

    .avatardrop .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
        padding-left: 20%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .navbar-nav .show .dropdown-menu a {
        font-size: 12px;
    }

    .d-flex.justify-content-center.align-items-center.ml-2.ml-lg-0 {
        padding-left: 0px !important;
    }

    .main-panel>.content {
        margin-left: 0px !important;
    }
    #root .sidebar {
        right: -5% !important;
    }   

    .col-sm-12.buttonsservice .savebtn button{
        width: 100% !important;
    }

    .emailFilters .savebtn button{
        width: 40% !important;
        margin-right: 0px !important;
    }
}

/* tablet screen */
@media only screen and (max-width: 820px) {

    .invoicingTables{
        margin-top: 60px !important;
    }

    .col-sm-5.equipmentssss h2{
        margin: 15px 0px;
    }

    form.myfilters1.custFiltwer {
        margin-top: 17px;
    }

    .yellowbtn.packBtnAdd {
        width: 120px !important;
    }

    .col-sm-5.checcKlisssst .col-sm-5 {
        padding-right: 0px !important;
        min-width: 100% !important;
    }

    form.dosaaaggeesss .col-sm-2 button{
        min-width: 86% !important;
    }

    .wordkorder .swiper-button-prev {
        position: absolute !important;
        right: 10% !important;
    }

    html,
    body {
        overflow-x: hidden;
    }

    .addReadingsssSaveBtn{
        width: 40% !important;
    }

    .row.newrow h4 {
        margin-top: 20px;
        font-size: 18px;
    }

    .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 16px 0 0;
        padding: 12px 50px;
    }

    .ant-tabs .ant-tabs-tab {
        margin: 0 16px 0 0 ;
        padding: 12px 50px;
    }




    .col-sm-2.customertype {
        margin-top: 0px !important;
    }

    .savebtn .button {
        width: 50%;
    }

    .savebtn button {
        width: 40%;
    }

    .col-md-8,
    .col-md-4 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .nav.mr-auto.name.navbar-nav {
        width: 100% !important;
        padding-left: 10px;
        flex: 0 0 50%;
        padding-right: 10px;
    }

    .ml-auto.hassan.navbar-nav {
        display: flex !important;
        flex-direction: inherit;
        justify-content: space-between;
        margin-top: 15px;
    }

    .btn.btn-fill {
        background-color: #F0B51B !important;
        border: unset !important;
    }

    .name .nav-item {
        text-align: center;
    }

    .navbar-nav .nav-item {
        display: block;
        width: 100%;
        margin-left: 0px !important;
    }

    .searchform.nav-item input {
        width: 95%;
    }

    .avatardrop .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
        padding-left: 2%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .navbar-nav .show .dropdown-menu a {
        font-size: 12px;
    }

    .name span {
        color: #000 !important;
        font-size: 20px;
    }

    .sameheight .card {
        height: auto !important;
    }

    .workSlider .swiper-button-prev {
        position: absolute !important;
        right: 40% !important;
    }

    .routedashboard.mainpage.laborReporttable {
        margin-top: 65px !important;
    }

    form.tableFilters.invoiceFilter {
        right: -1.25%;
        top: 4%;
    }

    form.invoiceFilter .ant-picker {
        width: 85% !important;
    }

    .workdetail .col-sm-9 {
        padding-left: 10px;
    }

    .col-sm-12.progressbar {
        padding-left: 0px;
        /* padding-right: 0px; */
    }

    .col-sm-12.progressbar .col-sm-8{
        padding-left: 0px ;
    }

    .col-sm-12.progressbar .col-sm-6{
        padding-left: 0px ;
    }
    
    .workdetail .col-sm-9 {
        padding-left: 10px;

    }
    .row.workdetail .col-sm-2{
        padding-right: 0px;
    }

    .routeDashboardTable b{
        margin-left: 10px;
    }
}

@media screen and (max-width: 700px){
    .fomik .generalFilters input {
        min-width: 90% !important;
        border: 1px solid #c9c8c8 !important;
    }    


}

/* mobile screen */
@media only screen and (max-width: 575px) {
    .col-sm-4.poolWaterBody{
        padding: 0px !important;
    }

    .fomik {
        padding: 0px;
    }

    .col-sm-5.equipmentssss h2 {
        margin: 0px 0px 16px;
    }

    .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
        display: inline-flex;
        flex-wrap: nowrap;
        direction: ltr;
        width: 260px !important;
    }

    .row.profitreportTableesssss .col-sm-3{
        padding-left: 0px !important;
    }
    .col-sm-4.totalProfitsssss {
        display: flex;
        justify-content: flex-start;
    }
    
    .row.profitreportTableesssss .col-sm-9{
        padding-left: 0px !important;
    }
    .col-sm-6.generalFiltersResultText p{
        font-size: 16px !important;
    }

    form.dosaaaggeesss .col-sm-2 button{
        min-width: 97% !important;
    }

    .monthFilterSelect{
        width: 100% !important;
    }

    .col-sm-6.monthFilter{
        padding: 0px 15px !important;
    }
      
    .col-sm-6.monthDatePicker .ant-picker {
        width: 100% !important;
        height: 50px !important;
    }

    .col-sm-6.totalProfits{
        padding: 0px !important;
    }

    .col-sm-12.profitListingTable{
        padding-left: 0px !important;
    }

    form.dosaaaggeesss .col-sm-5{
        padding-right: 0px !important;
        padding-left: 0px !important;
        padding-bottom: 5px !important;
        width: 97% !important;
      }

    .wordkorder .swiper-button-prev {
        position: absolute !important;
        right: 12% !important;
        top: -4% !important;
    }

    .invoicingReport .col-sm-10{
        max-width: 100% !important;
        min-width: 100% !important;
      }
      
      .invoicingReport .col-sm-2{
        max-width: 100% !important;
        min-width: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: -25px !important;
      }

    .wordkorder .swiper-button-next{
        top: -4% !important;
    }
    .valueForm {
        margin-left: 0;
    }

    .col-sm-5.checcKlisssst .deltIcon{
        width: 10% !important;
        margin-top: 7px !important;
    }

    .col-sm-5.checcKlisssst .col-sm-10{
        width: 85% !important;
    }

    .workRadio .ant-radio-group.ant-radio-group-outline {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 98%;
        border: 1px solid rgb(203, 203, 203);
        margin-left: 15px;
        border-radius: 5px;
      }

    .valueForm1 {
        margin-left: 0px !important;
        width: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 15px !important;
        margin-top: -80px !important;
    }

    .valuesForm1Checkbox{
        width: 100% !important;
    }

    .readinngForrrmmmsssMain{
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
      }
    .shoppingTab {
        margin-left: 0px !important;
        margin-top: 25px;
    }

    .routefilters select {
        margin-bottom: 5px;
        margin-left: 0px;
    }

    form.tableFilters.invoiceFilter {
        right: -2.5% !important;
        top: 3%;
    }
    form.myfilters input {
        width: 100%;
    }

    form.tableFilters .ant-picker {
        width: 73% !important;
        border-radius: 8px;
        margin: 0px 0px !important;
        margin-top: 10px !important;
        margin-right: 4px !important;
    }

    .custFiltwer .select1{
        width: 100% !important;
    }

    .addReadingsssSaveBtn{
        width: 60% !important;
      }

    .custFiltwer .select2{
        width: 100% !important;
    }

    form.custFiltwer button{
        width: 20% !important;
        padding: 8px !important;
        margin-top: 5px !important;
    }

    .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 16px 0 0;
        padding: 12px 40px;
    }

    .ant-tabs .ant-tabs-tab {
        margin: 0 16px 0 0 ;
        padding: 12px 40px;
    }

    .workSlider .swiper-button-prev {
        position: absolute !important;
        right: 25% !important;
    }

    .row.workFilter .col-sm-2{
        padding-bottom: 20px !important;
    }

    .custFiltwer input {
        height: 38px !important;
    }

    .custFiltwer button {
        height: 39px;
    }

    .col-sm-5.workHeaderrrr{
        width: 50% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: flex-start !important;
        padding: 0px !important;
        margin: 0px !important;
    }

    .col-sm-7.workHeaderrrr{
        width: 50% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: end !important;
        padding: 0px !important;
        margin: 0px !important;
    }

    .workTabbssssss{
        margin-top: 0px;
        margin-left: -15px;
        margin-bottom: 20px;
    }

    html,
    body {
        overflow-x: hidden !important;
    }

    nav.navbar.navbar-expand-lg.navbar-light.bg-light {
        padding: 10px 5px !important;
        background: #1A4A5B !important;
    }

    .navbar .navbar-brand {
        font-weight: 400;
        margin: 5px 0px;
        font-size: 20px;
        color: #fff !important;
    }

    .navbar .navbar-toggler .burger-lines {
        background-color: #fff !important;
    }

    .searchform.nav-item {
        display: block !important;
        margin: auto;
    }

    .navbar-light .navbar-nav .nav-link {
        color: #fff !important;
    }

    .ml-auto.hassan.navbar-nav {
        display: flex;
        flex-direction: inherit;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .avatardrop .dropdown-menu {
        transform: scale(1) !important;
        background: #F0B51B !important;
        margin-top: 15px !important;
    }

    .notNotidrop .dropdown-menu {
        transform: scale(1) !important;
        background: #F0B51B !important;
        margin-top: 15px !important;
    }

    .row.owner .col-sm-5.equipmentssss{
        max-width: 49% !important;
        min-width: 49% !important;
    }

    .row.owner .col-sm-5.equipmentssss h2{
        margin-bottom: 0px;
    }

    .row.owner .col-sm-7.equipmentssss{
        max-width: 50% !important;
        justify-content: flex-end !important;
    }

    .row.owner{
        display: flex !important;
        flex-direction: row !important;
    }

    .notNotidrop .dropdown-menu a {
        color: #fff !important;
    }

    .dropdown .btn {
        color: #fff !important;
    }

    /* .filters .dropdown-menu.show {
        margin-top: 35px !important;
    } */

    .bluebtn.addSubscribrrr {
        height: 39px !important;
        /* padding: 0px !important; */
        width: 145px !important;
        /* font-weight: 400; */
    }

    .yellowbtn.packBtnAdd {
        width: 110px !important;
        height: 39px !important;
    }

    form.myfilters1 input {
        width: 80%;
    }

    .workorder h4 {
        font-weight: 600 !important;
        font-size: 20px !important;
    }

    /* col css */
    .workdetail .col-sm-1 {
        width: 10% !important;
    }

    .workdetail .col-sm-9 {
        width: 75% !important;
    }

    .workdetail .col-sm-2 {
        width: 15% !important;
    }

    .workdetail .col-sm-12.progressbar {
        width: 100% !important;
    }

    .workdetail .col-sm-8 {
        width: 50% !important;
    }

    .workdetail .col-sm-4 {
        width: 50% !important;
    }

    .workdetail .col-sm-6 {
        width: 50% !important;
    }

    .workdetail .text-right {
        text-align: right !important;
        justify-content: end !important;
    }

    .workdetail .col-sm-10 {
        width: 80% !important;
    }

    .icon-big.text-center.icon-warning h2 {
        font-size: 28px;
    }

    .btn.btn-fill {
        background-color: #F0B51B !important;
        border: unset !important;
    }

    .sameheight .card {
        height: auto !important;
    }

    .d-flex.justify-content-center.align-items-center.ml-2.ml-lg-0 {
        padding-left: 0px !important;
    }

    .bluebtn {
        font-size: 12px;
    }

    .col-sm-7.right {
        margin-top: 25px;
    }

    .col-sm-7.right.chemicalBtns {
        padding: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
        margin-left: 0px !important;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    form.myfilters input {
        font-size: 12px;
        text-align: center;
    }

    form.myfilters select {
        width: 70% !important;
        border-radius: 15px !important;
        margin-top: 5px !important;
        font-size: 12px;
        margin-right: 5px !important;
    }

    form.myfilters button {
        width: 20%;
    }

    .grayFilters input {
        width: 80% !important  ;
      }

    form.myfilters {
        padding: 0px;
    }
    .routedashboard.invoiceTable {
        overflow-x: scroll;
    }

    .col-sm-5.equipmentssss{
        display: flex;
        align-items: flex-start;
    }

    .col-sm-7.equipmentssss{
        display: flex;
        align-items: flex-start;
        margin-top: 5px !important;
        margin-left: 0px !important;
        justify-content: flex-start;
        padding: 0px !important;
        max-width: 100% !important;
    }   

    .col-sm-12 form.userFilter input {
        width: 48.5% !important;
        margin-right: 5px !important;
    }

    .col-sm-12 form.userFilter select {
        width: 75% !important;
        margin-right: 5px !important;
        border-radius: 15px !important;
        margin-top: 5px !important;
    }

    .col-sm-12 form.userFilter button {
        width: 22% !important;
        margin-top: 5px !important;
        padding: 7px !important;
    } 

    .col-sm-5.slog{
        display: flex;
        align-items: flex-start;
    }

    .col-sm-7.slog{
        display: flex;
        align-items: flex-start !important;
        width: auto;
    }

    .col-sm-12.laborReport{
        display: flex;
        align-items: flex-start !important;
    }

    .col-sm-5.installedItems{
        display: flex;
        align-items: flex-start !important;
    }

    form.tableFilters select{
        width: 48% !important;
    }

    /* .addcustomers {
        padding-left: 10px;
    } */

    .valuesBtnnnnY input{
        min-width: 100%;
    }

    .col-sm-1.valuesBtnnnn{
        margin-left: 15px;
        margin-top: 7px;
    }

    .accccc{
        margin-left: 18px !important;
    }

    .adddDosagesss{
        min-height: auto;
    }

    .customertypesssss{
        margin-top: 10px !important ;
    }

    .prospectusss{
        width: 200px !important;
        margin-left: 13px !important;
        margin-top: 0px !important;
    }

    .prospectusss .ant-switch{
        margin-top: -80px !important;
    }

    .row.grayshade .col-sm-6{
        margin-bottom: 20px !important;
    }

    .col-sm-1.addReadingsssB {
        margin-left: 7px !important;
        margin-top: 15px !important;
    }
    form#Customer {
        width: 100%;
        /* margin-left: 0px !important; */
    }
      
}

@media only screen and (max-width: 480px) {
    .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 16px 0 0;
        padding: 12px 30px;
    }

    .wordkorder .swiper-button-prev {
        position: absolute !important;
        right: 20% !important;
    }

    .modal-body{
        padding: 0px  !important;
    }

    .ant-tabs .ant-tabs-tab {
        margin: 0 16px 0 0 ;
        padding: 12px 30px;
    }

    .addReadingsssSaveBtn{
        width: 100% !important;
    }
    
    /* .bodyroute{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .bodyroute.row .bluebox{
        width: 20% !important;
    }

    .bodyroute.row .col-sm-4{
        width: 75%;
    }

    .bodyroute.row .col-sm-3{
        width: 89% !important;
        text-align: left;
    }

    .bodyroute.row .col-sm-1{
        width: 10% !important;
    } */


    .col-sm-2.bluebox{
        margin-left: 10px !important;
    }

    .bodyroute.row .col-sm-1{
        margin-left: 15px !important;
    }

    .col-sm-1.editWO_iconnn1{
        width: 50% !important;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    
    .col-sm-12.submitbtn .saaavvveeeBtnnn{
        width: 45% !important;
        padding: 10px 20px !important;
    }

    .editWorkHeader.row .col-sm-7{
        display: flex;
        justify-content: flex-start;
    }

    .col-sm-1.editWO_iconnn2{
        width: 50% !important;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .addCustomersButtonnnn{
        flex-direction: column !important;
        gap: 10px !important;
        align-items: flex-start !important;
    }
    
    td, th {
        padding: 20px 10px !important;
    }

    .accordion-item {
        padding: 0px;
    }
    .accordion-item {
        background: #f0f0f0;
        /* padding: 20px; */
        border-radius: 10px;
        margin-top: 10px;
    }

    .datefilter {
        padding-left: 10px;
        padding-top: 0px;
    }

    .emailFilters .savebtn button {
        width: 50% !important;
        margin-right: 0px !important;
    }

    .col-sm-7.right.chemicalBtns {
        flex-wrap: wrap;
        gap: 10px !important;
    }

    .col-sm-12 form.userFilter input {
        width: 100% !important;
        margin-right: 0px !important;
        margin-bottom: 5px !important;
    }

    .col-sm-12 form.userFilter select {
        width: 75% !important;
        margin-right: 5px !important;
        border-radius: 15px !important;
        margin-top: 0px !important;
    }

    .col-sm-12 form.userFilter button {
        width: 23% !important;
        margin-top: 0px !important;
        padding: 7px !important;
    } 
}
.col-sm-2.switchbtn.prospect {
    padding: 0px !important;
    margin-top: 0px !important;
}

.addTagsSaveBtn{
    display: flex;
    flex-direction: row;
    justify-content: flex-end !important;
}

.col-sm-12.editShoppingFormSaveBtn button{
  width: 100px !important;
}

.col-sm-5.editShoppingFormSubHeader{
  min-width: 100% !important;
}

.row.serviceLogDetailPageCols{
  display: flex;
  flex-direction: row;
  align-items: flex-start !important;
  margin-left: -15px !important;
}

.addTechnicianFormmmss{
  padding: 25px !important;
}

.addTechnicianFormmmss .col-sm-12.submitbtn button{
  width: 100px !important;
  padding: 10px 0px !important;
}

.addTechnicianFormmmss .col-sm-12{
  padding-bottom: 15px !important;
}

.modal-body .addEquipments{
  padding: 0px !important;
}

.addEquipments button{
  padding: 10px 0px !important;
  width: 100px !important;
}

.row .addEquipments{
  padding: auto !important;
}

.editEquipments{
  background-color: #fff !important;
  padding: 0px !important;
  border-radius: 12px;
  padding-top: 0px !important;
  width: 95%;
  margin: auto;
}

form.workOrderType input{
  width: 90% !important ;
}

form.dosaaaggeesss{
  min-width: 100% !important;
}

form.dosaaaggeesss input{
  width: 100% !important;

}
form.dosaaaggeesss .col-sm-5{
  padding-right: 0px !important;
  padding-left: 0px !important;
  width: 45% !important;
}

form.dosaaaggeesss .col-sm-2{
  padding-right: 0px !important;
  padding-left: 0px !important;
}


form.dosaaaggeesss .col-sm-2 button{
  min-width: 93% !important;
}

.accccc{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start !important;
}

.addDosageBtn button{
  width: 150px !important;
}

.wordkorder .row.fomik.addWorkOrderTypeForm{
  margin-left: 0px !important;
}

.ant-modal-footer .ant-btn-primary {
  height: 33px !important;
}

.ant-modal-footer .ant-btn-default {
  height: 33px !important;
  border-radius: 15px !important;
/*   
  font-weight: 700;
  text-transform: uppercase; */
}

.generalFilters .ant-radio-group.ant-radio-group-outline {
  width: 100%;
  margin: 0 auto;
}
.generalFilters .col-sm-6 p, .col-sm-12 p {
  text-align: start;
}
.generalFilters .ant-form-item .ant-form-item-control-input {
  margin: 0 auto;
  width: 100%;
}
.generalFilters p {
  color: #000;
  font-size: 14px;
  padding-top: 15px;
}

.generalFilters .col-sm-12{
  max-width: 100% !important;
  padding: 0px !important;
}

.col-sm-12.generalFilterSelect{
  width: 100% !important;
  display: flex;
  flex-direction: column !important;
  /* gap: 10px !important; */
}

.fomik .generalFilters input{
  max-width: 90% !important;
  border: 1px solid #c9c8c8 !important;
}

.fomik .generalFilters .ant-checkbox-wrapper {
  width: 90% !important;
}

.generalFiltersResult {
  width: 100% !important;
  background: #ffffff !important;
  border-radius: 20px !important;
  padding-top: 20px !important;
  /* display: flex;
  flex-direction: row !important; */
}

.col-sm-6.monthFilter .ant-form-item{
  margin-bottom: 0px !important;
}

.col-sm-6.monthFilter{
  padding: 0px !important;
}

.col-sm-6.monthDatePicker{
  display: flex;
  justify-content: flex-end;
}


.col-sm-6.generalFiltersResultBtn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem !important;
  /* padding-right: 0px !important;
  margin-right: 0px !important; */
}

.col-sm-6.generalFiltersResultBtn button{
  height: 41px !important;
  width: 100px;
  padding: 10px 0px !important;
  /* margin-right: 0px !important; */
}

.row.generalSububHeader{
  display: flex;
  flex-direction: row !important;
}

.col-sm-6.generalSububHeaderBtn{
  display: flex;
  justify-content: flex-end !important;
}

.col-sm-6.generalSububHeaderBtn button{
  width: 100px !important;
  padding: 10px 0px !important;
}

.row.generalSettingHeader{
  display: flex;
  padding-left: 40px !important;
}

.row.generalSettingHeader p{
  font-weight: 800;
  color: #1a4a5b;
  text-transform: uppercase;
  font-size: 36px;
}

.col-sm-4.totalProfits{
  display: flex;
  justify-content: flex-end;
  margin-left: -50px;
} 

.col-sm-4.totalProfitsssss{
  display: flex;
  justify-content: flex-end;
}

/* .profileListingFlex{
  display: flex;
  flex-direction: row;
} */

.col-sm-8.serviceProfit{
  display: flex;
  justify-content: flex-end;
}

.col-sm-3.serviceProfit{
  display: flex;
  justify-content: flex-end;
}

.row.serviceProfit{
  margin-top: 20px;
}

.row.profileReportDescription{
  margin-top: 25px;
  padding: 20px;
}

.col-sm-6.profitMaker{
  padding: 0px !important;
}

.col-sm-5.serviceLocation{
  padding-left: 0px !important;
  word-break: break-word;
}

.col-sm-4.poolWaterBody{
  /* padding-left: 0px !important; */
  word-break: break-word;
}

.col-sm-3.totalProfitsDetails{
  padding-left: 0px !important;
}

.monthFilterSelect{
  width: 50% !important;
}

.col-sm-6.monthDatePicker .ant-picker {
  width: 50% !important;
  height: 50px !important;
}

.col-sm-12.profitListingTable{
  overflow-x: scroll !important;
}

/* .col-sm-12.col-sm-12.profitListingTable table{
  width: 100% !important;
} */
.invoiceFilter .ant-picker-input >input{
  background-color: #ffffff !important;
}

.gstTable table{
  overflow-x: scroll !important;
}

.col-sm-6.generalFiltersResultText{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row.profitreportTableesssss .col-sm-12.tableee{
  width: 95% !important;
  overflow-x: scroll;
  padding-left: 0px !important;
}

.row.profitreportTableesssss .col-sm-12 table {
  width: 100% !important;
  overflow-x: scroll;
}

.col-sm-12.profitListingTable table{
  width: 100% !important;
}

.modals .pooltypeModaaallllll{
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
}

.col-sm-2.radioBtnAddPoolCustomer span{
  color: black !important;
  text-align: left !important;
}

.col-sm-2.radioBtnAddPoolCustomer {
  padding: 12px 0px !important;
}

/* .col-sm-12.radioAddPoolCustomer{
  padding-left: 0px !important;
} */

.row.routefiltersahsan .col-sm-3{
  min-width: 25% !important;
  max-width: 25% !important;
}

.row.row.routefiltersahsan{
  margin-right: 15px !important;
}

.col-sm-1.dltIconnnnnnnn .ant-form-item{
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.row.equipmentModaalllll{
  width: 95% !important;
}

.row.uploadImagePoOOLll input {
  background-color: #fff;
  padding: 12px 10px;
  border-radius: 8px;
  height: 50px;
  border: 1px solid #efefef !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  background: #f3f1f1;
    border-radius: 10px !important;
}


.col-sm-6.uploadImageeePoolAccordion{
  display: flex;
  flex-direction: row;
}

.col-sm-12.uploadImageeePoolAccordionBtn{
  display: flex;
  justify-content: flex-end;
}

.row.additionalDetailsss{
  width: 100% !important;
  position: relative;
}

.additionalDetailsss .col-sm-5{
  min-width: 48.5% !important;
  max-width: 97% !important;
}

.additionalDetailsss .col-sm-12{
  min-width: 97% !important;
  max-width: 97% !important;
}

.row.additionalDetailsss .formIcon{
  position: absolute;
  top: 40%;
  right: 1%;
}

.row.additionalDetailsssOne{
  width: 100% !important;
  position: relative;
}

.additionalDetailsssOne .col-sm-5{
  min-width: 48.5% !important;
  max-width: 97% !important;
}

.additionalDetailsssOne .col-sm-12{
  min-width: 97% !important;
  max-width: 97% !important;
}

.row.additionalDetailsssOne .formIconOne{
  position: absolute;
  top: 40%;
  right: 1%;
}

/* additionalDetailsssService */
.row.additionalDetailsssService{
  width: 100% !important;
  position: relative;
}

.additionalDetailsssService .col-sm-5{
  min-width: 48% !important;
  max-width: 96% !important;
}

.additionalDetailsssService .col-sm-12{
  min-width: 96% !important;
  max-width: 96% !important;
}

.row.additionalDetailsssService .formIcon{
  position: absolute;
  top: 40%;
  right: 2%;
}

.col-sm-6.addPoolTypeBtn{
  display: flex;
  justify-content: flex-end;
}

.col-sm-12.savebtn .viewServiceLocation{
  width: 200px !important;
}

.col-sm-12.savebtn .updateCustomer{
  width: 180px !important;
}

.col-sm-12.buttonsservice .profServiceLocationBlue{
  width: 150px !important;
  padding: 10px 20px !important;
  height: 40px;
  border-radius: 0 !important;
  text-align: center;
  /* width: 100%; */
  border-radius: 10px !important;
  height: 50px !important;
    margin: 0px 5px !important;
}

.col-sm-6.addbuttons .profServiceLocationBlue{
  width: 100px !important;
  padding: 10px 20px !important;
  height: 40px;
  border-radius: 0 !important;
  text-align: center;
  /* width: 100%; */
  border-radius: 10px !important;
  height: 50px !important;
    margin: 0px 5px !important;
}

.row.fomik .profServiceLocationAccordion{
  margin-right: 15px !important;
}

.col-sm-12.buttonsservice .profServiceLocationyellow{
  width: 180px !important;
}

.bluebtn.addSubscribrrr{
  background: #FAB51C !important;
  border-radius: 15px;
  color: #ffffff;
}


.custFiltwer .fa{
  color: #ffffff;
}


.custFiltwer button{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.custFiltwer input{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.graphfilter .legend{
  color: #676767;
}

.row.newrow h4 {
  margin-top: 20px;
}

form.myfilters1.custFiltwer {
  margin-top: 15px;
}

.content.dasssssshhhBoarrddd .container-fluid{
  padding-left: 0px !important;
}

.col-sm-12.textAREAAA .ant-input-textarea-affix-wrapper.ant-input-affix-wrapper{
  border-radius: 10px;
  border: 1px solid #efefef !important;
}



@media  only screen and (max-width: 1580px){
  .col-sm-4.customerNameeeee{
    min-width: 32.25% !important;
    max-width: 32.25% !important;
  }
  .graph h4{
    font-size: 20px;
  }


  .customers h2{
    font-size: 26px;
  }
}

@media only screen and (max-width: 1440px){
  .customers h2{
    font-size: 20px;
  }

  .fomik input {
    height: 40px;
}

  .yellowbtn.packBtnAdd{
    height: 41px !important;
    border-radius: 15px !important;
    padding: 0px 10px !important;
    width: 172px !important;
  }

  form.myfilters1.custFiltwer {
    margin-top: 20px;
}

  .custFiltwer input{
    height: 40px !important;
    padding: 0px 10px !important;
    /* padding-top: 0px !important; */
  }

  .custFiltwer button{
    height: 41px;
    padding: 0px !important;
    /* padding-top: 0px; */
  }

  .bluebtn.addSubscribrrr {
    height: 41px !important;
    padding: 0px !important;
    width: 172px !important;
    font-weight: 400;
  }

  .graph h4{
    font-size: 18px;
  }

  .graph h4 span {
    font-weight: 500;
    margin-left: 10px;
  }

  .woNoti{
    width: 18px;
    height: 18px;
  }

  .workdetailNAme{
    font-size: 14px !important;
    margin-bottom: 0px !important;
    font-weight: 500;
  }

}

@media only screen and (max-width: 1366px){
  .col-sm-4.customerNameeeee{
    min-width: 32% !important;
    max-width: 32% !important;
  }

  form.myfilters1.custFiltwer {
    margin-top: 10px;
  }

  .yellowbtn.packBtnAdd{
    height: 41px !important;
    border-radius: 15px !important;
    padding: 0px 10px !important;
    width: 150px !important;
  }
}

@media only screen and (max-width: 1280px){
  .col-sm-4.customerNameeeee{
    min-width: 31% !important;
    max-width: 31% !important;
  }
}

@media only screen and (max-width: 1200px){
  .col-sm-4.customerNameeeee{
    min-width: 30% !important;
    max-width: 30% !important;
  }

  .legend span {
    font-size: 12px;
}
}

@media only screen and (max-width: 1100px){
  .col-sm-4.customerNameeeee{
    min-width: 28% !important;
    max-width: 28% !important;
  }
}